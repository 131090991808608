export default {
  admin_noDataForSelectionParametars: 'There is no data for selected parameters',
  betslip_betslip: 'betslip',
  betslip_maxBetAmount: 'Max bet amount is',
  betslip_maxLengthIs: 'Max length is',
  betslip_maxWinLimit: 'Max win limit',
  betslip_minLengthIs: 'Min length is',
  betslip_stake: 'stake',
  betslip_startBettingMessage: 'Select respective result to start betting',
  betslip_ticketResolving: 'ticket resolving',
  betslip_totalCombinations: 'total combinations',
  betslip_totalStake: 'total stake',
  betslip_ticketAccepted: 'ticket accepted',
  betslip_balanceWarning: 'Not enough credit on youir balance.',
  betslip_maxTicketAmount: 'Max ticket amount',
  betslip_minBetAmount: 'Min bet amount',
  betslip_minTicketAmount: 'Min ticket amount',
  betslip_stakePerBet: 'Stake per bet',
  betslip_toReturn: 'Pos. win',
  betslip_invalidDrawType: 'Invalid draw type provided',
  betslip_invalidOutcomeNumbers: 'Invalid played numbers for the selected outcome type',
  betslip_invalidStandardBet: 'Standard bet is not valid',
  betslip_missingRequestUuid: 'Missing request uuid',
  betslip_numbersRange: 'Played numbers must be in the tournament range',
  betslip_sameDrumBonusBall: 'Cannot send bonus ball for the same drum',
  betslip_haveOnBetslip: 'Already have on betslip',
  betslip_maxBetCountIs: 'Max bet count is',
  betslip_oddsHaveChangedFrom: 'Odds have change from',
  betslip_acceptAndPayin: 'Accept and Pay in',
  betslip_oddsHaveChanged: 'Odds have change',
  betslip_outcomeIsAlready: 'Outcome is already added to the betslip',
  game_NumbersBetting: 'Lottery',
  general_accept: 'accept',
  general_all: 'all',
  general_bonus: 'bonus',
  general_cancel: 'cancel',
  general_check: 'check',
  general_close: 'close',
  general_combo: 'combo',
  general_country: 'country',
  general_currentlyUnavailable: 'Betting on this event is currently unavailable.',
  general_deleteShort: 'del',
  general_error: 'error',
  general_event: 'event',
  general_invalidTicketId: 'Invalid ticket ID',
  general_lastTickets: 'last tickets',
  general_lost: 'lost',
  general_market: 'market',
  general_maxWinShort: 'max win',
  general_number: 'number',
  general_numbers: 'numbers',
  general_odd: 'odd',
  general_open: 'open',
  general_payIn: 'pay in',
  general_payinTax: 'payin tax',
  general_payout: 'payout',
  general_payoutAmount: 'payout amount',
  general_payoutTax: 'payout tax',
  general_pick: 'pick',
  general_possiblePayoutTax: 'possible payout tax',
  general_possibleWin: 'possible win',
  general_requiredField: 'required field',
  general_results: 'results',
  general_search: 'search',
  general_selection: 'selection',
  general_signIn: 'sign in',
  general_single: 'single',
  general_ticket: 'ticket',
  general_ticketCheck: 'ticket check',
  general_time: 'time',
  general_totalOdds: 'total odds',
  general_winnings: 'winnings',
  general_won: 'won',
  general_pleaseWait: 'please wait',
  general_today: 'today',
  general_clear: 'clear',
  general_edit: 'edit',
  general_reset: 'reset',
  general_save: 'save',
  general_selectAll: 'select all',
  general_totalTickets: 'total tickets',
  general_add: 'add',
  general_ticketCode: 'ticket code',
  general_updateBet: 'update bet',
  general_payedout: 'payout',
  general_random: 'random',
  general_addBet: 'add bet',
  general_more: 'more',
  general_info: 'info',
  general_successs: 'success',
  general_categories: 'categories',
  general_others: 'others',
  general_popular: 'popular',
  general_success: 'success',
  general_ticketId: 'ticket ID',
  general_all_countries: 'all countries',
  general_popular_lottery: 'popular lottery',
  general_tournamentCancelled: 'tournament cancelled',
  general_in_play: 'in play',
  general_warning: 'warning',
  general_clearAll: 'Clear all',
  general_refresh: 'refresh',
  general_sortByCategories: 'Sort by categories',
  general_sortByTime: 'Sort by time',
  general_translations: 'Translations',
  general_betslip: 'Betslip',
  general_bonus_balls: 'Bonus balls',
  general_pay_in: 'Pay In',
  general_stake_per_bet: 'Stake per bet',
  general_back: 'Back',
  general_bet: 'Bet',
  general_to: 'to',
  general_betID: 'Bet ID',
  general_eventID: 'Event ID',
  general_outcomeID: 'Outcome ID',
  general_match_locked: 'Match locked',
  general_name: 'Name',
  general_offer: 'Offer',
  general_selectAnOption: 'Select an option',
  general_selectionContent: 'Selection content',
  general_type: 'Type',
  general_noDataAvailable: 'There is not any data available for the specified parameters!',
  general_oddsMgmt: 'Odds management',
  general_quickPick: 'Quick pick',
  general_internalError: 'Internal error',
  general_status: 'Status',
  general_id: 'ID',
  general_deleteLockedBets: 'Delete locked bets?',
  general_acceptAndProceed: 'Accept and Proceed',
  general_min_payment_per_ticket_rule: 'Minimum payment per ticket is {value}.',
  general_max_payment_per_ticket_rule: 'Maximum payment per ticket is {value}.',
  general_max_bet_amount_rule: 'Maximum bet amount is {value}.',
  general_min_bet_amount_rule: 'Minimum bet amount is {value}.',
  general_noInternet: 'It appears you are offline. Please check your internet connection.',
  general_application: 'Application',
  general_channel: 'Channel',
  general_category: 'Category',
  general_tournament: 'Tournament',
  general_player: 'Player',
  general_operator: 'Operator',
  general_betshop: 'Betshop',
  general_hour: 'Hour',
  general_week: 'Week',
  general_month: 'Month',
  general_maxPaymentAmount: 'Maximum payment amount',
  general_maxTicketCount: 'Maximum number of tickets',
  general_maxSameNumbersSelection: 'Maximum bets with same numbers',
  general_futureBets: 'Future bets',
  general_future_bet: 'Future bet',
  general_stake_with_future_bets: 'Stake with all future bets',
  nb_bonusBall: 'bonus ball',
  shop_betIsNotFound: 'Bet is not found',
  shop_outcomeIsNotInOffer: 'Outcome is not in offer',
  shop_matchWithId: 'Match with ID',
  shop_notfoundAlreadyStarted: 'Not found or already started',
  shop_allEventsStarted: 'All events have already started',
  shop_webCodeInvalidOrExpired: 'Web code is invalid or has expired',
  shop_nextEventStartingIn: 'Next event starting in',
  shop_newTicket: 'New ticket',
  shop_print: 'Print',
  shop_results: 'Results',
  shop_random: 'Random',
  shop_fullOffer: 'Full offer',
  shop_resetTicket: 'Reset ticket',
  shop_ticketType: 'Ticket type',
  shop_offerResultsPrint: 'Offer/results Print',
  shop_printPreview: 'Print preview',
  shop_offerSelect: 'Offer print selection',
  shop_resultsSelect: 'Results print selection',
  shop_bettingDisabled: 'Betting disabled',
  shop_printOffer: 'Print Offer',
  shop_resultsOffer: 'Results Offer',
  shop_removeDuplicateNumbers: 'Remove duplicate numbers',
  shop_printPreviewIsGenerating: 'Please wait. Print preview is generating',
  shop_sameLocationPayoutRestriction: 'Ticket can be payed out only from bet shop where played',
  shop_unknownTicketCheck: 'Unknown ticket',
  shop_addToBetslip: 'Add to betslip',
  shop_removeBallsSelection: 'Remove balls selection',
  general_system: 'System',
};
